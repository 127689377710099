import qs from 'qs'
import axios from 'axios'
import api from '@/services/api'
import {
    getLocalVar,
    removeLocalVar,
    setLocalVar,
} from '@/util/localstorage-helper'

function navigateToLoginPage() {
    removeLocalVar('accessToken')
    removeLocalVar('auth__refresh__token')
    if (window.location.pathname !== '/login') {
        window.location.href = '/login'
    }
}

const lang = localStorage.getItem('lang') || 'ru'

async function retryApiCall(config) {
    const access_token = getLocalVar('accessToken')
    const headers = {}


    if (access_token) {
        headers['Authorization'] = `${access_token}`
    }

    // config.paramsSerializer = (params) => {
    //   console.log(params, 'params')
    //   return qs.stringify(params, {
    //     encode: false,
    //     skipNulls: false,
    //     withCredentials: true,
    //   })
    // }

    const _axios = axios.create({
        baseURL: config.baseURL,
        headers
    })

    const data = () => {
        if (config.data) {
            return JSON.parse(config.data)
        }
        return config.data
    }
    return await _axios({
        method: config.method,
        url: config.url,
        data: data()
    })
        .then((response) => response)
        .catch(error => Promise.reject(error))
}

async function waitApiCall(config) {
    return await new Promise((resolve) => {
        const starter = setInterval(() => {
            const pending = sessionStorage.getItem('pending')
            if (!parseInt(pending)) {
                resolve()
                clearInterval(starter)
            }
        }, 50)
    }).then(async () => await retryApiCall(config))
}

async function refreshToken() {
    const refreshToken = getLocalVar('auth__refresh__token')
    if (refreshToken) {
        sessionStorage.setItem('pending', '1')
        return await api.auth.updateToken(refreshToken)
            .then(async (response) => {
                const {
                    access_token
                } = await response.data
                setLocalVar('auth__refresh__token', refresh_token)
                setLocalVar('accessToken', access_token)
                return 1
            })
            .catch(() => 1)
            .finally(() => {
                sessionStorage.setItem('pending', '0')
                return 1
            })
    } else {
        navigateToLoginPage() //qwertyuiuytrewqwertyui
        return 0
    }
}

export function axiosRequestInterceptResponse(config) {
    const access_token = getLocalVar('accessToken')
    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            encode: false,
            skipNulls: false,
            withCredentials: true,
        })
    }

    const requestConfig = Object.assign({}, config)

    if (!config.headers['Authorization'] && config.url !== 'auth/login') {
        requestConfig.headers['Authorization'] = access_token
    }

    requestConfig.headers['content-language'] = 'ru'

    return requestConfig
}

export async function axiosResponseInterceptorError(error) {
    if (error.response) {
        const { status } = error.response
        switch (status) {
            case 401:
                removeLocalVar('accessToken')
                removeLocalVar('user')
                removeLocalVar('role')
            // break;
            // case 403:
            // const {role_id} = JSON.parse(localStorage.getItem('user'))
            // api.role.getRole(role_id).then(res => {
            //     setLocalVar('role', JSON.stringify(res.data))
            // }).catch(() => {
            //     return false
            // })
        }
        return Promise.reject(error)

    }
}
