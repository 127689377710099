import { getCategories, addCategory, updateCategory, deleteCategory } from "@/api/category";

export default {
    FETCH_CATEGORIES({ commit }, params) {
        return getCategories(params).then((res) => {
            commit("SET_PRODUCTS", res.data);
        });
    },

    ADD_CATEGORY({ commit }, params) {
        return addCategory(params).then((res) => {
            commit("SET_PRODUCTS", res.data);
        });
    },

    UPDATE_CATEGORY({ commit }, params) {
        return updateCategory(params).then((res) => {
            commit("SET_PRODUCTS", res.data);
        });
    },

    DELETE_CATEGORY(_, id) {
        return deleteCategory(id)
    },
};