import { axiosInstance } from "@/services/core/base";
import Core from "@/services/core";

class Users extends Core {
  constructor(axios = axiosInstance) {
    super(axios);
  }

  getUsersList(params) {
    return this.get("/users", { params });
  }

  getUser(userId) {
    return this.get(`/users/${userId}`);
  }

  updateUser(userId, body) {
    return this.put(`/users/${userId}`, body);
  }

  createUser(data) {
    return this.post("/users", data);
  }

  deleteUser(userId) {
    return this.delete(`/users/${userId}`);
  }
}

export default Users;
