import { getBrands, addBrand, updateBrand, deleteBrand } from "@/api/brand";

export default {
  FETCH_BRANDS({ commit }, params) {
    return getBrands(params).then((res) => {
      commit("SET_BRANDS", res.data);
    });
  },

  ADD_BRAND({ commit }, params) {
    return addBrand(params).then((res) => {
      commit("SET_BRANDS", res.data);
    });
  },

  UPDATE_BRAND({ commit }, params) {
    return updateBrand(params).then((res) => {
      commit("SET_BRANDS", res.data);
    });
  },

  DELETE_BRAND(_, id) {
    return deleteBrand(id)
  },
};
