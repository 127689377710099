export default class Permission {
    static permission = null
    static user = null
    static role = null

    static initializeUser(user = null, role = null) {
        if (this.user === null && this.role === null) {
            this.user = user
            this.role = role
            this.permission = role.permissions
        }
        return this.user
    }

    static getUserPermission(property) {
        return this.permission[property]
    }

    static hasAdminRole() {
        if (this.role)
            return !!this.role.is_admin
        return false
    }
}
