export default [
    {
        path: '/card-logs/:id',
        name: 'cardLogs',
        component: () => import('@/views/card-logs/Index'),
        meta: {
            requiresAuth: "cards",
        },
    },
]
