import http from "@/libs/axios";

export function getProducts(params) {
    return http({
        url: "/products",
        method: "GET",
        params,
    });
}

export function addProducts(params) {
    return http({
        url: "/products/create",
        method: "POST",
        data: params,
    });
}

export function updateProduct(params) {
    return http({
        url: "/products/update",
        method: "POST",
        data: params.data,
    });
}

export function deleteProduct(id) {
    return http({
        url: `/products/${id}`,
        method: "DELETE",
    });
}

export function updateBrand(params) {
    return http({
        url: "/categories/update",
        method: "POST",
        data: params,
    });
}

export function deleteBrand(id) {
    return http({
        url: `/categories/${id}`,
        method: "DELETE",
    });
}