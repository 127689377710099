import { getProducts, addCategory, updateCategory, deleteCategory, deleteProduct, addProducts, updateProduct } from "@/api/product";

export default {
    FETCH_PRODUCTS({ commit }, params) {
        return getProducts(params).then((res) => {
            commit("SET_PRODUCTS_NAME", res.data);
        });
    },
    ADD_PRODUCTS({ commit }, params) {
        return addProducts(params).then((res) => {
            commit("SET_PRODUCTS_NAME", res.data);
        });
    },
    DELETE_PRODUCT(_, id) {
        return deleteProduct(id)
    },

    UPDATE_PRODUCT({ commit }, params) {
        return updateProduct(params).then((res) => {
            commit("SET_PRODUCTS_NAME", res.data);
        });
    },

    // DELETE_CATEGORY(_, id) {
    //     return deleteCategory(id)
    // },
};