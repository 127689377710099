import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Settings extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchSettings() {
    return this.get('/api/settings')
  }

  updateSettings(body) {
    return this.post('/api/settings', body)
  }

}

export default Settings

