import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class CardLogs extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getCardLogs(cardId, params) {
        return this.get(`dashboard/logs/${cardId}`, {params})
    }

    getCardRetryVisa(cardId) {
        return this.get(`/dashboard/resendVisa/${cardId}`)
    }


}

export default CardLogs

