import http from "@/libs/axios";

export function getBrands(params) {
  return http({
    url: "/brands",
    method: "GET",
    params,
  });
}

export function addBrand(params) {
  console.log(params);
  return http({
    url: "/brands/create",
    method: "POST",
    data: params,
  });
}

export function updateBrand(params) {
  console.log(params)
  return http({
    url: "/brands/update",
    method: "POST",
    data: params,
  });
}

export function deleteBrand(id) {
  return http({
    url: `/brands/${id}`,
    method: "DELETE",
  });
}