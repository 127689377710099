import actions from './Actions';
import mutations from './Mutations';
import getters from './Getters';
import state from './State';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};