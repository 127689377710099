export default [
    {
        path: '/card-type',
        name: 'cardTypes',
        component: () => import('@/views/card-type/Index'),
        meta: {
            requiresAuth: "card_types",
        },
    },
]
