import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core/index'

class Auth extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    signIn(login) {
        return this.post('/login', login)
    }

    // updateToken(refreshToken) {
    //   return this.post('auth/refreshToken', { refreshToken })
    // }

    getMe() {
        return this.post('/auth/get-info')
    }

    // setMe(userId, data) {
    //     return this.put(`/dashboard/users/${userId}`, data)
    // }
    //
    // updatePassword(data) {
    //     return this.post('/dashboard/users/password/change', data)
    // }

    logOut() {
        return this.post('/logout')
    }
}

export default Auth

