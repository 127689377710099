export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/user/Index'),
        meta: {
            requiresAuth: "users",
        },
    },
]
