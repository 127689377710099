import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class Cards extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getCards(params) {
        return this.get('/dashboard/cards', {params})
    }

    getCard(cardId) {
        return this.get(`/dashboard/cards/${cardId}`)
    }

    updateCard(cardId, body) {
        return this.put(`/dashboard/cards/${cardId}`, body)
    }

    createCard(data) {
        return this.post('dashboard/cards', data)
    }

    deleteCard(cardId) {
        return this.delete(`/dashboard/cards/${cardId}`)
    }

    importCard(data) {
        return this.post('/dashboard/importCard/upload', data)
    }

    importStatus() {
        return this.get('/dashboard/importCard/getFileStatus')
    }

    importSuccessFile() {
        return this.get('/dashboard/importCard/getSuccessImport')
    }

    importFailedFile() {
        return this.get('/dashboard/importCard/getFailedImport')
    }

    importExistFile() {
        return this.get('/dashboard/importCard/getExistImport')
    }

    saveImport(data) {
        return this.post('/dashboard/importCard/saveImport', data)
    }

}

export default Cards

