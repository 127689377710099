export default [
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/role/Index'),
        meta: {
            requiresAuth: "roles",
        },
    },
    {
        path: '/roles/:id',
        name: 'roles-update',
        component: () => import('@/views/role/id'),
        meta: {
            requiresAuth: "roles",
        },
    },
    {
        path: '/role/create',
        name: 'role-create',
        component: () => import('@/views/role/id'),
        meta: {
            requiresAuth: "roles",
        },
    },


]
