import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class Issuer extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getIssuers(params) {
        return this.get('/dashboard/issuers', {params})
    }

    getIssuer(issuerId) {
        return this.get(`/dashboard/issuers/${issuerId}`)
    }

    updateIssuer(issuerId, body) {
        return this.put(`/dashboard/issuers/${issuerId}`, body)
    }

    createIssuer(data) {
        return this.post('dashboard/issuers', data)
    }

    deleteIssuer(issuerId) {
        return this.delete(`/dashboard/issuers/${issuerId}`)
    }


}

export default Issuer

