import http from "@/libs/axios";

export function getDepartments(params) {
    return http({
        url: "/departments",
        method: "GET",
        params,
    });
}

export function addDepartment(params) {
    return http({
        url: `/departments/create`,
        method: "POST",
        data: params,
    });
}

export function updateDepartment(params) {
    return http({
        url: `/departments/update`,
        method: "POST",
        data: params.data,
    });
}

export function deleteDepartment(id) {
    return http({
        url: `/departments/${id}`,
        method: "DELETE",
    });
}