import Auth from "@/services/auth"
import Users from '@/services/users'
import Role from '@/services/role'
import Country from '@/services/country'
import Cards from '@/services/cards'
import CardType from '@/services/card-type'
import CardLogs from '@/services/card-logs'
import Issuer from '@/services/issuer'
import Settings from '@/services/settings'
import Category from '@/services/category'


export default {
    auth: new Auth(),
    users: new Users(),
    role: new Role(),
    country: new Country(),
    cards: new Cards(),
    cardType: new CardType(),
    cardLogs: new CardLogs(),
    issuer: new Issuer(),
    settings: new Settings(),
    category: new Category(),
}
