export default [
    {
        path: '/import-checker',
        // name: 'import-checker',
        component: () => import('@/views/import/Index'),
        meta: {
            requiresAuth: "cards",
        },
        children: [
            {
                path: 'success',
                name: 'import-success',
                component: () => import('@/views/import/success'),
            },
            {
                path: 'failed',
                name: 'import-failed',
                component: () => import('@/views/import/failed'),
            },
            {
                path: 'exist',
                name: 'import-exist',
                component: () => import('@/views/import/exist'),
            }
        ]
    },
]
