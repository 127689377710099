export default [
  {
    path: "/category",
    name: "category",
    component: () => import("@/views/category/Index"),
  },
  {
    path: "/department",
    name: "department",
    component: () => import("@/views/department/Index"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("@/views/brand/Index"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/product/Index"),
  },
];
