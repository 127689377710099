import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class CardType extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getCardTypes(params) {
        return this.get('/dashboard/cardTypes', {params})
    }

    getCardType(cardTypeId) {
        return this.get(`/dashboard/cardTypes/${cardTypeId}`)
    }

    updateCardType(cardTypeId, body) {
        return this.put(`/dashboard/cardTypes/${cardTypeId}`, body)
    }

    createCardType(data) {
        return this.post('dashboard/cardTypes', data)
    }

    deleteCardType(cardTypeId) {
        return this.delete(`/dashboard/cardTypes/${cardTypeId}`)
    }


}

export default CardType

