import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class Role extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getRoleList(params) {
        return this.get('/role', {params})
    }

    getRole(roleId) {
        return this.get(`/role/${roleId}`)
    }

    updateRole(roleId, body) {
        return this.put(`/role/${roleId}`, body)
    }

    createRole(data) {
        return this.post('/role', data)
    }

    deleteRole(roleId) {
        return this.delete(`/role/${roleId}`)
    }

    transferRole(roleId, data) {
        return this.put(`/role/change/${roleId}`, data)
    }


}

export default Role

