export default [
    {
        path: '/cards',
        name: 'cards',
        component: () => import('@/views/card/Index'),
        meta: {
            requiresAuth: "cards",
        },
    },
]
