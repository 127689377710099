import { getDepartments, addDepartment, updateDepartment, deleteDepartment } from "@/api/department";

export default {
  FETCH_DEPARTMENTS({ commit }, params) {
    return getDepartments(params).then((res) => {
      commit("SET_DEPARTMENTS", res.data);
    });
  },

  ADD_DEPARTMENT({ commit }, params) {
    return addDepartment(params).then((res) => {
      commit("SET_PRODUCTS", res.data);
    });
  },

  UPDATE_DEPARTMENT({ commit }, params) {
    return updateDepartment(params).then((res) => {
      commit("SET_PRODUCTS", res.data);
    });
  },

  DELETE_DEPARTMENT(_, id) {
    return deleteDepartment(id)
  },
};
