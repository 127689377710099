import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class Country extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    getCategories(params) {
        return this.get('/categories', {params})
    }

    getCountry(roleId) {
        return this.get(`/dashboard/countries/${roleId}`)
    }

    updateCountry(roleId, body) {
        return this.put(`/dashboard/countries/${roleId}`, body)
    }

    createCountry(data) {
        return this.post('dashboard/countries', data)
    }

    deleteCountry(roleId) {
        return this.delete(`/dashboard/countries/${roleId}`)
    }


}

export default Country

