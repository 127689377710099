import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import uploadFile from './upload-file'
import category from './category'
import brand from './brand'
import department from './department'
import product from './product'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        uploadFile,
        category,
        brand,
        department,
        product
        // 'app-ecommerce': ecommerceStoreModule,
    },
    strict: process.env.DEV,
})
