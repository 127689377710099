import api from "@/services/api";

export default {
    namespaced: true,
    state: {
        status: '',
        successData: {},
        failedData: {},
        existData: {},
        finishFileImport: false,
        // generateFile: false
        changeWatcher: 0
    },

    getters: {
        getStatus(state) {
            return state.status;
        },

        getFinishStatus(state) {
            return state.finishFileImport;
        },

        getWatcher(state) {
            return state.changeWatcher;
        }

        // getGeneratePosition(state) {
        //     return state.generateFile
        // }
    },
    mutations: {
        updateStatus(state, status) {
            state.status = status
        },

        updateWatcher(state, position) {
            state.changeWatcher = position
        },

        // changeGenerateFile(state, position) {
        //     state.generateFile = position
        // },

        updateFinish(state, finishFileImport) {
            state.finishFileImport = finishFileImport
        },

        updateSuccessData(state, successData) {
            state.successData = successData
        },

        updateFailedData(state, failedData) {
            state.failedData = failedData
        },

        updateExistData(state, existData) {
            state.existData = existData
        },

    },
    actions: {
        async statusFile({state, commit, dispatch}, vm) {
            const generateFile = localStorage.getItem('generateFile')
            if (generateFile) {
                try {
                    const response = await api.cards.importStatus()
                    commit("updateStatus", response.data.status);
                } catch (error) {
                    if (error.response) {
                        vm.$toast(error.response.data.message, "error");
                    }
                }
                if (state.status === 'finished') {
                    localStorage.setItem('generateFile', 'false')
                } else if (state.status === 'successful') {
                    localStorage.setItem('generateFile', 'false')
                    dispatch('fetchFileData')
                } else {
                    setTimeout(() => {
                        commit('updateWatcher', state.changeWatcher + 1)
                        dispatch('statusFile')
                    }, 3000);
                }
            }
        },

        async fetchFileData({state, commit, dispatch, $router}, vm) {
            await api.cards.importSuccessFile().then((response) => {
                commit('updateSuccessData', response.data)
            }).catch((error) => {
                if (error.response) {
                    vm.$toast(error.response.data.message, "error");
                }
            })
            await api.cards.importFailedFile().then((response) => {
                commit('updateFailedData', response.data)
            }).catch((error) => {
                if (error.response) {
                    vm.$toast(error.response.data.message, "error");
                }
            })
            await api.cards.importExistFile().then((response) => {
                commit('updateExistData', response.data)
            }).catch((error) => {
                if (error.response) {
                    vm.$toast(error.response.data.message, "error");
                }
            })
            commit('updateFinish', true)
        }

    },
}