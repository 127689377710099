import Vue from "vue";
import VueRouter from "vue-router";

// Routes

import home from "@/router/routes/home";
import users from "@/router/routes/users";
import roles from "@/router/routes/roles";
// import country from '@/router/routes/country';
import category from "@/router/routes/category";
import cards from "@/router/routes/cards";
import cardTypes from "@/router/routes/card-type";
import cardLogs from "@/router/routes/card-logs";
// import issuer from '@/router/routes/issuer';
import importFile from "@/router/routes/import-file";

import system from "@/router/routes/system";
import pages from "@/router/routes/pages";
// import Error from "@/router/routes/error-404";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    {
      path: "/",
      redirect: { name: "home" },
    },
    // {
    //     path: '/',
    //     redirect: {name: 'users'},
    // },
    ...home,
    ...users,
    ...roles,
    // ...country,
    ...cards,
    // ...issuer,
    ...cardTypes,
    ...cardLogs,
    ...importFile,
    ...pages,
    ...system,
    ...category,
    {
      path: "*",
      name: "not_found",
      component: () => import("@/views/error"),
      // redirect: 'Error',
    },
  ],
});

import Permission from "@/permission";

router.beforeEach((to, from, next) => {
  const AUTH_TOKEN = localStorage.getItem("accessToken");

  if (to.name === "auth-login") return next();
  if (AUTH_TOKEN) {
    const user = JSON.parse(localStorage.getItem("user"));
    const role = JSON.parse(localStorage.getItem("role"));

    Permission.initializeUser(user, role);
    const { requiresAuth } = to.meta;
    if (requiresAuth) {
      const perm = Permission.getUserPermission(requiresAuth);
      if (!!role.is_admin || (perm && perm.view)) {
        return next();
      } else {
        return next({
          name: "not_found",
        });
      }
    }
    if (to.path === "/") {
      return next({ name: "home" });
    } else {
      return next();
    }
  } else {
    // return next({ name: "home" })
    return next({ name: "auth-login" });
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
