import Vue from 'vue'
import axios from 'axios'

const lang = localStorage.getItem('lang') || 'ru'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================

    baseURL: process.env.VUE_APP_URL,
    timeout: 20000,
    headers: {
        'Authorization': localStorage.getItem('accessToken'),
    }
})

Vue.prototype.$http = axiosIns

export default axiosIns
