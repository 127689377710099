import http from "@/libs/axios";

export function getCategories(params) {
  return http({
    url: "/categories",
    method: "GET",
    params,
  });
}

export function addCategory(params) {
  return http({
    url: "/categories/create",
    method: "POST",
    data: params,
  });
}

export function updateCategory(params) {
  return http({
    url: "/categories/update",
    method: "POST",
    data: params,
  });
}

export function deleteCategory(id) {
  return http({
    url: `/categories/${id}`,
    method: "DELETE",
  });
}